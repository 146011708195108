.loading {
  position: fixed;
  left: 0;
  right: 0;
  top: calc(50% - 20px);
  margin: auto;
  height: 40px;
  width: 40px;
  z-index: 324324324;
}
