.carousel-item {
  height: 100%;
  position: relative;

  &:before {
    background-image: linear-gradient(
      45deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 100%
    );
    content: "\00a0";
    height: 100%;
    position: absolute;
    width: 100%;
  }

  img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}
