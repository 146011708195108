.team-container {
  // background-image: url("/assets/images/speckled-bg.png");
  // background-position-y: top;
  // background-size: cover;
  // background-size: 100%;
  // background-repeat: no-repeat;

  padding: 32px 0;

  .dex-team-member {
    margin-bottom: 56px;

    img {
      border-radius: 50%;
      height: 200px;
      width: 200px;
    }

    h3 {
      margin: 8px 0 -4px 0;
    }
  }
}

@media (min-width: 767px) {
  .team-container {
    .team-content {
      margin: 32px;
    }
  }
}

@media (max-width: 767px) {
  .team-container {
    padding-top: 16px;

    .team-content {
      margin: 16px;
    }
  }
}
