.services-container {
  .services-content {
    .service-card-content {
      ul {
        margin-top: 1em;
      }
    }
  }
}

@media (min-width: 767px) {
  .services-content {
    margin: 32px;
  }
}

@media (max-width: 767px) {
  h1 {
    text-align: center;
  }
  .services-content {
    margin: 16px;
  }
}
