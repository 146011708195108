.about-container {
  background-color: $gray-100;
  background-image: url('/assets/images/speckled-bg.png');

  background-size: cover;
  background-repeat: no-repeat;
  color: $black;
  padding: 32px 0;

  p {
    font-weight: 500;
  }

  h1 {
    width: 100%;
    text-align: center;
  }

  .about-us-row {
    margin-top: 32px;

    .about-col {
      font-weight: bold;

      .about-number {
        font-size: 5em;
        margin-bottom: -16px;
      }

      .about-text {
        font-size: 1.5em;
        text-transform: uppercase;
        padding: 0 32px;
      }
    }

    .about-col-primary {
      color: $black;
    }

    .about-col-secondary {
      color: $soft-pink;
    }
  }
}

@media (min-width: 767px) {
  .about-container {
    .about-content {
      margin: 32px;

      p {
        text-align: center;
        width: 700px;
        margin: 0 auto;
        font-weight: 500;
      }
    }
  }
}

@media (max-width: 767px) {
  .about-container {
    padding-top: 16px;

    .about-content {
      margin: 16px;
    }
  }
}
