.app-footer {
  margin-top: 2rem;
  background: $gray-100;
  padding: 1.25rem;
  margin-left: 1.5em;
  margin-right: 1.5em;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  .footer-bottom {
    width: 100%;
    .logo {
      width: 3rem;
      height: auto;
      margin: 4px;
    }
  }
}
