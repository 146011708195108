.home-carousel {
  .carousel {
    .carousel-inner {
      h2 {
        color: $white;
        text-transform: none;
        font-size: 3em;
      }

      p {
        font-size: 1.5em;
      }
    }

    .carousel-item:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0),
        50%,
        rgba(0, 0, 0, 0.75)
      );
    }
  }
}

@media (min-width: 360px) {
  .carousel {
    .carousel-inner {
      height: 350px;

      h2 {
        color: $white;
        text-transform: none;
        font-size: 3em;
      }

      p {
        font-size: 1.5em;
      }
    }
  }
}
