.contact-container {
  background-image: url('/assets/images/controller.png');
  background-position-x: right;
  background-size: cover;
  // background-size: 100%;
  background-repeat: no-repeat;

  padding: 32px 0;

  .progress-bar {
    border-radius: 50px;
    margin: 0 0 15px 0;
    height: 5px;
  }

  .progress-bar-50 {
    background: linear-gradient(
      90deg,
      $secondary-base 0 50%,
      rgba(255, 255, 255, 0.75) 50% 0%
    );
  }

  .progress-bar-100 {
    background: linear-gradient(
      90deg,
      $secondary-base 0 100%,
      rgba(255, 255, 255, 0.75) 50% 0%
    );
  }
}

@media (min-width: 767px) {
  .contact-container {
    .contact-content {
      margin: 32px;
    }
  }
}

@media (max-width: 767px) {
  .contact-container {
    padding-top: 16px;

    .contact-content {
      margin: 16px;
    }
  }
}
